import { baseUrl } from './base-url';

interface Props {
  name: string;
  link: string | null;
  comment: string | null;
  file?: File | null;
  turnstileToken?: string | null;
}

export const suggestPub = async ({
  name,
  link,
  comment,
  file,
  turnstileToken,
}: Props) => {
  try {
    const data = new FormData();
    data.append('name', name);

    if (link) {
      data.append('link', link);
    }
    if (comment) {
      data.append('comment', comment);
    }
    if (file) {
      data.append('menu', file);
    }
    if (turnstileToken) {
      data.append('turnstileToken', turnstileToken);
    }

    const response = await fetch(`${baseUrl}/api/pub/suggest`, {
      method: 'POST',
      body: data,
    });

    return response.status === 200;
  } catch (err) {
    console.log('Failed to send pub suggestion', err);
  }
};
