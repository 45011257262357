import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Pub } from '../../../../../types';
import { HappyHours } from '../../../../components/happy-hours';
import { Menu } from '../../../../components/menu';
import { spacing } from '../../../../components/styled';
import { Summary } from '../../../../components/summary';
import { SectionWrapper } from '../../../shared/components/section-wrapper';
import { useUserDetails } from '../../../shared/hooks/user-user-details';

const dummyPubs: Pub[] = [
  {
    googlePlaceId: 'ChIJf5MypWBxhlQRkWSJq43uXZA',
    name: "Smith's Irish Pub",
    website: 'http://www.smithsofgastown.ca/',
    latitude: 49.28284009999999,
    longitude: -123.1041375,
    happyHours: {
      '0': [],
      '1': [
        {
          startTime: '15:00',
          endTime: '18:00',
        },
      ],
      '2': [
        {
          startTime: '15:00',
          endTime: '18:00',
        },
      ],
      '3': [
        {
          startTime: '12:00',
          endTime: '00:00',
        },
      ],
      '4': [
        {
          startTime: '15:00',
          endTime: '18:00',
        },
      ],
      '5': [
        {
          startTime: '15:00',
          endTime: '18:00',
        },
      ],
      '6': [],
    },
    drinks: [
      {
        label: 'Guinness',
        price: '7.50',
      },
      {
        label: "Smith's Lager",
        price: '6.25',
      },
      {
        label: 'Magners',
        price: '7.50',
      },
      {
        label: 'Stella Artois',
        price: '7.50',
      },
      {
        label: 'Red/White Wine 9oz',
        price: '10',
      },
      {
        label: 'Red/White Wine 6oz',
        price: '7',
      },
      {
        label: 'Baby Guinness',
        price: '5',
      },
      {
        label: 'House Highballs',
        price: '6',
      },
      {
        label: 'Bucket of the Day',
        price: '27',
      },
    ],
    food: [],
    vicinity: '208 &, 212 Carrall Street, Vancouver',
    links: [],
    openingHours: [
      {
        close: {
          day: 1,
          time: '0000',
          hours: 0,
          minutes: 0,
        },
        open: {
          day: 0,
          time: '1000',
          hours: 10,
          minutes: 0,
        },
      },
      {
        close: {
          day: 2,
          time: '0000',
          hours: 0,
          minutes: 0,
        },
        open: {
          day: 1,
          time: '1200',
          hours: 12,
          minutes: 0,
        },
      },
      {
        close: {
          day: 3,
          time: '0000',
          hours: 0,
          minutes: 0,
        },
        open: {
          day: 2,
          time: '1200',
          hours: 12,
          minutes: 0,
        },
      },
      {
        close: {
          day: 4,
          time: '0000',
          hours: 0,
          minutes: 0,
        },
        open: {
          day: 3,
          time: '1200',
          hours: 12,
          minutes: 0,
        },
      },
      {
        close: {
          day: 5,
          time: '0000',
          hours: 0,
          minutes: 0,
        },
        open: {
          day: 4,
          time: '1200',
          hours: 12,
          minutes: 0,
        },
      },
      {
        close: {
          day: 6,
          time: '0300',
          hours: 3,
          minutes: 0,
        },
        open: {
          day: 5,
          time: '1200',
          hours: 12,
          minutes: 0,
        },
      },
      {
        close: {
          day: 0,
          time: '0300',
          hours: 3,
          minutes: 0,
        },
        open: {
          day: 6,
          time: '1000',
          hours: 10,
          minutes: 0,
        },
      },
    ],
  },
];

export const HappyHourView = () => {
  const navigate = useNavigate();
  const {
    data: { firstName, lastName },
  } = useUserDetails();
  const [step, setStep] = useState(0);
  const [pub, setPub] = useState(dummyPubs[0]);

  return (
    <SectionWrapper>
      <Container>
        <h2>{`Welcome ${firstName} ${lastName}`}</h2>
        <Button onClick={() => navigate('/business/dashboard')}>
          Dashboard
        </Button>
        <Content>
          <h3>{`${pub.name}`}</h3>
          {step === 0 && <Summary pub={pub} />}
          {step === 1 && (
            <>
              <HappyHours
                times={pub.happyHours}
                openingHours={pub.openingHours}
                onUpdate={(newTimes) => {
                  setStep(0);
                  setPub({ ...pub, happyHours: newTimes });
                }}
              />
            </>
          )}
          {step === 2 && (
            <>
              <Menu
                drinks={pub.drinks}
                food={pub.food}
                onUpdate={(newMenus) => {
                  setStep(0);
                }}
              />
            </>
          )}
          {step == 0 && (
            <ButtonWrapper>
              <Button onClick={() => setStep(1)}>Update Hours</Button>
              <Button onClick={() => setStep(2)}>Update Menu</Button>
            </ButtonWrapper>
          )}
          <ButtonWrapper>
            {/* <Button onClick={() => setStep(0)}>Cancel</Button> */}
            {/* <Button onClick={() => setStep(0)}>Save</Button> */}
            {/* {step === 0 && <Button onClick={() => setStep(0)}>Publish</Button>} */}
          </ButtonWrapper>
        </Content>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  width: 100%;
  padding-left: 50px;
`;

const Content = styled.div`
  margin: ${spacing.xl} 0;
`;

const ButtonWrapper = styled.div`
  margin-top: ${spacing.xl};
`;
