import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { ProtectedRoute } from '../../../components/protected-route';
import { AdminDashboard } from '../../admin/dashboard';
import { BusinessLogin } from '../../business/business-login';
import { BusinessDashboard } from '../../business/dashboard';
import { HappyHourView } from '../../business/dashboard/happy-hour';
import { Home } from '../../home';
import { Legal } from '../../legal';
import { PrivacyPolicy } from '../../legal/privacy-policy';
import { Support } from '../../legal/support';
import { TermsAndConditions } from '../../legal/terms-and-conditions';
import { TokenProvider } from '../../shared/state/token-context';
import { BusinessUserProvider } from '../../shared/state/user-context';
import { FooterOne } from './footer-one';
import { FooterTwo } from './footer-two';
import { HashLinker } from './hash-linker';
import { Header } from './header';

export const Root = () => {
  return (
    <TokenProvider>
      <BusinessUserProvider>
        <BrowserRouter basename={'/'}>
          <HashLinker />
          <Wrapper>
            <Header />
            <MainContent>
              <Routes>
                <Route index element={<Home />} />
                <Route
                  path='admin/dashboard'
                  element={
                    <ProtectedRoute
                      element={AdminDashboard}
                      requireAdmin={true}
                    />
                  }
                />
                <Route path='business/login' element={<BusinessLogin />} />
                {/* <Route
                  path='business/register'
                  element={<BusinessRegister />}
                /> */}
                <Route
                  path='business/dashboard'
                  element={<ProtectedRoute element={BusinessDashboard} />}
                />
                <Route
                  path='business/dashboard/happy-hour'
                  element={<ProtectedRoute element={HappyHourView} />}
                />
                <Route path='legal' element={<Legal />} />
                <Route
                  path='legal/privacy-policy'
                  element={<PrivacyPolicy />}
                />
                <Route path='legal/support' element={<Support />} />
                <Route path='legal/terms' element={<TermsAndConditions />} />
                <Route path='*' element={<Home />} />
              </Routes>
              <div>
                <FooterOne />
                <FooterTwo />
              </div>
            </MainContent>
          </Wrapper>
        </BrowserRouter>
      </BusinessUserProvider>
    </TokenProvider>
  );
};

const Wrapper = styled.div`
  font-family: 'Montserrat';

  h1 {
    line-height: 1.25;
  }

  h2 {
    line-height: 1.25;
  }

  h3 {
    line-height: 1.25;
  }

  p {
    line-height: 1.5;
  }

  input {
    font-size: 1em;
  }

  textarea {
    font-size: 1em;
  }
`;

const MainContent = styled.div`
  display: flex;
  min-height: calc(100vh - 69px);
  flex-direction: column;
  justify-content: space-between;
`;
