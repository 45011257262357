import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { UserRole } from '../../../../types';

interface ContextType {
  firstName: string;
  setFirstName: (name: string) => void;
  lastName: string;
  setLastName: (name: string) => void;
  email: string;
  setEmail: (email: string) => void;
  role: UserRole;
  setRole: (role: UserRole) => void;
}

const defaultContext = {
  firstName: '',
  setFirstName: () => {},
  lastName: '',
  setLastName: () => {},
  email: '',
  setEmail: () => {},
  role: 'client' as UserRole,
  setRole: () => {},
};

export const BusinessUserContext = createContext<ContextType>(defaultContext);

export const useBusinessUser = () => useContext(BusinessUserContext);

export const BusinessUserProvider = ({ children }: PropsWithChildren<{}>) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<UserRole>('client');

  return (
    <BusinessUserContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        role,
        setRole,
      }}
    >
      {children}
    </BusinessUserContext.Provider>
  );
};
