import React from 'react';
import { SectionWrapper } from '../../shared/components/section-wrapper';
import { LegalTextContainer } from '../../shared/components/styled';

export const PrivacyPolicy = () => {
  return (
    <SectionWrapper>
      <LegalTextContainer>
        <h1>Privacy Policy</h1>
        <h5>Effective 21 October 2024</h5>
        <p>Welcome to the Vibe happy hour app for Android.</p>
        <h2>Data Collected by the App</h2>
        <p>
          To the best of my knowledge and belief, the Vibe app does not collect
          or store any personally identifible information from users.
        </p>
        <h2>Permissions Requested by the App and Why</h2>
        <h3>ACCESS_FINE_LOCATION</h3>
        <p>
          This permission is used to surface the user’s current location while
          using the map feature of the app and to order the list of
          estabilishments by order of nearest to them. This information is only
          used ephemerally and is not transfered or stored outside of the user’s
          device.
        </p>
        <h2>Contact Information</h2>
        <p>
          If any security vulnerabilities have been introduced or there
          questions regarding how the app protects your privacy, please send me
          an email at the address below.
        </p>
        <p>Brian Burns, Vancouver, BC, Canada</p>
        <p>brian@vibeapp.ca</p>
      </LegalTextContainer>
    </SectionWrapper>
  );
};
