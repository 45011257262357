import { Pub } from '../../types';
import { baseUrl } from './base-url';

export const fetchPubs = async () => {
  try {
    const result = await fetch(`${baseUrl}/api/pubs`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const pubs: Pub[] = await result.json();

    return pubs;
  } catch (err) {
    console.log('Failed to create or update pub.', err);
    throw err;
  }
};
