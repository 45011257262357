import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { MenuItem, Menus, Pub } from '../../types';
import { MenuList } from './menu-list';
import { AddButton } from './styled/buttons';
import { CustomHeading4 } from './styled/headings';

interface Props {
  food: Pub['food'];
  drinks: Pub['drinks'];
  onUpdate: (newMenu: Menus) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Menu = ({ food, drinks, onUpdate }: Props) => {
  const handleUpdate = (newItems: MenuItem[], foodUpdate?: boolean) => {
    if (foodUpdate) {
      const newMenus: Menus = { drinks, food: newItems };
      onUpdate(newMenus);
    } else {
      const newMenus: Menus = { food, drinks: newItems };
      onUpdate(newMenus);
    }
  };

  const handleAdd = (newFood: boolean) => {
    if (newFood) {
      handleUpdate([...food, { label: '', price: '' }], true);
    } else {
      handleUpdate([...drinks, { label: '', price: '' }], false);
    }
  };

  return (
    <Container>
      <CustomHeading4>Drinks</CustomHeading4>
      <MenuWrapper>
        <MenuList
          items={drinks}
          onUpdate={(newDrinks) => handleUpdate(newDrinks)}
        />
        <AddButton onClick={() => handleAdd(false)}>Add</AddButton>
      </MenuWrapper>
      <CustomHeading4>Food</CustomHeading4>
      <MenuWrapper>
        <MenuList
          items={food}
          onUpdate={(newFood) => handleUpdate(newFood, true)}
        />
        <AddButton onClick={() => handleAdd(true)}>Add</AddButton>
      </MenuWrapper>
      <Button onClick={() => onUpdate({ food, drinks })}>Cancel</Button>
    </Container>
  );
};
