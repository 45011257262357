import { useContext, useEffect, useState } from 'react';
import { baseUrl } from '../../../api/base-url';
import { useTokenContext } from '../state/token-context';
import { BusinessUserContext } from '../state/user-context';
import { useUserRole } from './user-user-role';

interface ResponseBody {
  firstName: string;
  lastName: string;
  email: string;
}

export const useUserDetails = () => {
  const data = useContext(BusinessUserContext);
  const { email, setFirstName, setLastName, setEmail, setRole } = data;
  const { setToken } = useTokenContext();
  const [error, setError] = useState(false);
  const userRole = useUserRole();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // TODO: If no token, logout? Could we get here without a token?
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}/api/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status !== 200) {
          setError(true);
        } else {
          const { firstName, lastName, email }: ResponseBody =
            await response.json();

          const accessToken = localStorage.getItem('token') as string;
          setToken(accessToken);
          setFirstName(firstName);
          setLastName(lastName);
          setEmail(email);
          setRole(userRole);
          setError(false);
        }
      } catch (err) {
        setError(true);
        console.log('Error logging in');
      }
    };

    if (email === '') {
      fetchUserData();
    }
  });

  const clearUserData = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setRole('client');
    setToken('');
  };

  return { data, clearUserData };
};
