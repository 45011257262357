import React from 'react';
import styled from 'styled-components';
import { Pub } from '../../types';
import { hoursStep } from '../helpers/form-steps';
import { StandardWidthButton } from './styled/buttons';
import { Summary } from './summary';

interface Props {
  pub: Pub;
  setStep: (step: number) => void;
  fetchPlaceData: (refreshData?: boolean) => void;
}

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  border-color: grey;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  margin-bottom: 10px;
`;

export const ExistingStep = ({ pub, setStep, fetchPlaceData }: Props) => {
  return (
    <>
      <span>Details for this pub already exist, see below.</span>
      <HorizontalWrapper>
        <StandardWidthButton onClick={() => setStep(hoursStep)}>
          Manually Update
        </StandardWidthButton>
        <StandardWidthButton onClick={() => fetchPlaceData(true)}>
          AI Update
        </StandardWidthButton>
      </HorizontalWrapper>
      <Summary pub={pub} />
    </>
  );
};
