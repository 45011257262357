import { Button } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Pub } from '../../../../../types';
import { Form } from '../../../../components/form';
import { colors, spacing } from '../../../../components/styled';
import { deletePub } from '../../api/delete-pub';

interface Props {
  pubs: Pub[];
  setPubs: (pubs: Pub[]) => void;
}

export const PubsTab = ({ pubs, setPubs }: Props) => {
  const [showAddForm, setShowAddForm] = useState(false);

  const handleDelete = async (pubToDelete: Pub) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${pubToDelete.name}`
    );

    if (deleteConfirmed) {
      const response = await deletePub([pubToDelete.googlePlaceId]);

      if (response?.status === 200) {
        const newPubsList = pubs.filter(
          (pub) => pub.googlePlaceId !== pubToDelete.googlePlaceId
        );
        setPubs(newPubsList);
      }
    }
  };

  return (
    <>
      <Table>
        <Row>
          <CheckboxCell></CheckboxCell>
          <Cell>
            <span>Name</span>
          </Cell>
          <Cell>
            <span>Address</span>
          </Cell>
          <ButtonCell></ButtonCell>
          <ButtonCell></ButtonCell>
          <ButtonCell></ButtonCell>
        </Row>
        {pubs.map((pub) => {
          return (
            <Row key={pub.googlePlaceId}>
              <CheckboxCell>X</CheckboxCell>
              <Cell>
                <span>{pub.name}</span>
              </Cell>
              <Cell>
                <span>{pub.vicinity}</span>
              </Cell>
              <ButtonCell>
                <Button>Update</Button>
              </ButtonCell>
              <ButtonCell>
                <Button>Assign Owner</Button>
              </ButtonCell>
              <ButtonCell>
                <Button onClick={() => handleDelete(pub)}>Delete</Button>
              </ButtonCell>
            </Row>
          );
        })}
      </Table>
      {showAddForm ? (
        <Form />
      ) : (
        <Button onClick={() => setShowAddForm(true)}>Add a Business</Button>
      )}
    </>
  );
};

const Table = styled.div`
  border: 2px ${colors.paleGrey} solid;
`;

const Row = styled.div`
  display: flex;
  border-top: 1px ${colors.paleGrey} solid;
  align-items: center;
`;

const Cell = styled.div`
  padding: ${spacing.md};
  flex-basis: 30%;
`;

const CheckboxCell = styled(Cell)`
  flex-basis: 3%;
  text-align: center;
`;

const ButtonCell = styled(Cell)`
  flex-basis: 15%;
  text-align: center;
`;
