import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Button } from '@mui/material';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Turnstile from 'react-turnstile';
import styled from 'styled-components';
import { PlaceType } from '../../../../types';
import { suggestPub } from '../../../api/suggest-pub';
import { colors, spacing } from '../../../components/styled';
import { turnstileSiteKey } from '../../../helpers/constants';

interface Props {
  setSubmitted: (submitted: boolean) => void;
}

const isDev = process.env.NODE_ENV === 'development';

export const SuggestionForm = ({ setSubmitted }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const placesLib = useMapsLibrary('places');
  const [autocomplete, setAutocomplete] = useState<PlaceType | null>(null);
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  );
  const [link, setLink] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(null);
  const [imgFile, setImgFile] = useState<File | undefined | null>(null);
  const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!placesLib || !inputRef.current) return;

    const options: google.maps.places.AutocompleteOptions = {
      types: ['establishment'],
      componentRestrictions: { country: 'can' },
      fields: ['name'],
    };

    setAutocomplete(new placesLib.Autocomplete(inputRef.current, options));
  }, [placesLib]);

  useEffect(() => {
    if (!autocomplete) return;

    autocomplete.addListener('place_changed', () => {
      setPlace(autocomplete.getPlace());
    });
  }, [autocomplete]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submit = async () => {
      if (place && place.name) {
        const successful = await suggestPub({
          name: place.name,
          link,
          comment,
          file: imgFile,
          turnstileToken,
        });
        if (successful) {
          setSubmitted(true);
        } else {
          setError('There was an error submitting the suggestion.');
        }
      }
    };

    submit();
  };

  const handleAddLink = (e: ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  };

  const handleAddComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImgFile(e.target.files?.[0]);
  };

  return (
    <>
      {/* <h3>Share your favourite spot and help expand our listings!</h3> */}
      <StyledForm onSubmit={handleSubmit}>
        <StyledLabel htmlFor='business-name-input'>Business Name*</StyledLabel>
        <StyledInput
          id='business-name-input'
          ref={inputRef}
          placeholder='Business Name*'
          required
        />
        {!isMobileDevice && (
          <>
            <StyledLabel htmlFor='menu-link-input'>Menu Link</StyledLabel>
            <StyledInput
              id='menu-link-input'
              placeholder='Menu Link (Optional)'
              onChange={handleAddLink}
            />
          </>
        )}
        <StyledLabel htmlFor='comments-input'>Comments</StyledLabel>
        <StyleTextArea id='comments-input' onChange={handleAddComment} />
        {isMobileDevice && (
          <PhotoInputWrapper>
            <PhotoIconsWrapper>
              {imgFile ? (
                <ImagePreview src={URL.createObjectURL(imgFile)} alt='img' />
              ) : (
                <Button onClick={() => fileInputRef.current?.click()}>
                  <ButtonWrapper>
                    <AddAPhotoIcon />
                    <span>Capture Menu</span>
                  </ButtonWrapper>
                  <input
                    onChange={handleImageUpload}
                    multiple={false}
                    ref={fileInputRef}
                    type='file'
                    hidden
                  />
                </Button>
              )}
            </PhotoIconsWrapper>
          </PhotoInputWrapper>
        )}
        <Turnstile
          sitekey={turnstileSiteKey}
          onVerify={(token) => setTurnstileToken(token)}
        />
        <Button type='submit' disabled={!place}>
          Submit
        </Button>
        {error && <span style={{ color: 'red' }}>{error}</span>}
      </StyledForm>
    </>
  );
};

const StyledLabel = styled.label`
  padding-bottom: ${spacing.sm};
`;

const StyledInput = styled.input`
  width: 100%;
  margin-bottom: ${spacing.lg};
  margin-top: ${spacing.sm};
  padding: ${spacing.md};
  border: none;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.slate};
`;

const StyleTextArea = styled.textarea`
  width: 100%;
  margin-bottom: ${spacing.md};
  margin-top: ${spacing.sm};
  padding: ${spacing.md};
  resize: vertical;
  border: none;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.slate};
`;

const StyledForm = styled.form`
  width: 100%;
  color: ${colors.slate};
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${spacing.lg};
`;

const PhotoInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhotoIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.lg};
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const ImagePreview = styled.img`
  max-width: 100px;
`;
